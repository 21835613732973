import React from "react"

import Layout from "../components/layout"

import { graphql } from "gatsby"
import Footer from "../components/footer"
import Jobs from "../components/jobs"
import Services from "../components/services"
import Accordion from "../styles/Accordion"
import Button from "../styles/Button"
import SEO from "../components/seo"

export default function ServicePage({ data }) {
  return (
    <Layout>
      <SEO title="Leistungen" />
      <Services>
        <h4>{ data.leistungen.edges[0].node.frontmatter.marker }</h4>
        <h2
          dangerouslySetInnerHTML={{ __html: data.leistungen.edges[0].node.frontmatter.einstieg }}
        />
        {data.leistungen.edges[0].node.frontmatter.leistung.map((el, idx) => (
          <Accordion key={idx} title={el.titel}>
            <p>{ el.beschreibung }</p>
            <Button externalLink={'mailto:office@schiefermayr.energy'} primary>Jetzt Service anfragen</Button>
          </Accordion>
        ))}
      </Services>
      <Jobs></Jobs>
      <Footer></Footer>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    leistungen: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/leistungen/"}}) {
      edges {
        node {
          frontmatter {
            marker
            einstieg
            leistung {
              titel
              beschreibung
            }
          }
        }
      }
    }
  }
`
