import React from 'react'
import ContentWrapper from '../styles/ContentWrapper'

export default function Services({children}) {
    return (
        <ContentWrapper>
            {children}
        </ContentWrapper>
    )
}
