import React from 'react'
import ContentWrapper from '../styles/ContentWrapper'
import Accordion from '../styles/Accordion'
import styled from 'styled-components'
import { StaticQuery, graphql } from "gatsby"
import Button from '../styles/Button'

const StyledContentWrapper = styled(ContentWrapper)`
    .intro-container {
        p {
            width: 75%;
        }
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      .intro-container {
        p {
          width: 100%;
        }
      }
    }
`

export default function Jobs() {
    return (
      <StaticQuery
        query={graphql`
          query JobsQuery {
            kontakt: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/jobs/"}}) {
              edges {
                node {
                    frontmatter {
                        anzeigen
                        marker
                        w_beschreibung
                        einstieg
                        stelle {
                          ausschreibung {
                            publicURL
                          }
                          beschreibung
                          titel
                        }
                    }
                }
              }
            }
          }
        `}
        render={data => (
            <StyledContentWrapper>
                <div className="intro-container">
                    <h4>{ data.kontakt.edges[0].node.frontmatter.marker }</h4>
                    <h2
                    dangerouslySetInnerHTML={{ __html: data.kontakt.edges[0].node.frontmatter.einstieg }}
                    />
                    <p>{ data.kontakt.edges[0].node.frontmatter.w_beschreibung }</p>
                    {data.kontakt.edges[0].node.frontmatter.anzeigen && data.kontakt.edges[0].node.frontmatter.stelle.map((el, idx) => (
                        <Accordion key={idx} title={el.titel}>
                            <p>{ el.beschreibung }</p>
                            <Button externalLink={`mailto:office@schiefermayr.energy`} primary>Jetzt bewerben</Button>
                        </Accordion>
                    ))}
                </div>
            </StyledContentWrapper>
        )}
      />
    )
  }
