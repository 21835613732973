import React from 'react'

import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components"

const StyledHeader = styled(motion.header)`
  border-top: 1px solid ${props => props.theme.colors.primary};
  cursor: pointer;
  position: relative;
  padding: 2.5rem 0rem;

  h3 {
      margin: 0;
      padding: 0;
  }

  & div:last-of-type {
    border-bottom: 1px solid ${props => props.theme.colors.primary};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 2rem 0rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 1.8rem 0rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 1.2rem 0rem;
  }
`

const StyledSection = styled(motion.section)`
  overflow: hidden;

  p {
    margin-top: 0;
    transform-origin: top center;
  }

  a {
    margin-top: 0rem;
    margin-bottom: 3rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    a {
      margin-bottom: 2.5rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    a {
      margin-bottom: 2rem;
    }
  }
`

const StyledArrow = styled(motion.i)`
  border: solid ${props => props.theme.colors.primary};
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 11px;
  position: absolute;
  right: 2px;
  top: calc(50% - 22px);
  transform: rotate(45deg);

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 8px;
    border-width: 0 4px 4px 0;
    top: calc(50% - 16px);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 5px;
    border-width: 0 3px 3px 0;
    top: calc(50% - 11px);
  }
`

const variants = {
  expanded: { rotate: 225, y: "36%" },
  closed: { rotate: 45, y: 0 },
}

export default function Accordion( props ) {
    const [expanded, setExpanded] = React.useState(false);

    return (
        <section css={`
        &:last-of-type {
            border-bottom: 1px solid ${props => props.theme.colors.primary};
        }
        `}>
        <StyledHeader
            initial={false}
            onClick={() => setExpanded(!expanded)}
        >
            <h3>{ props.title }</h3>
            <StyledArrow 
              animate={expanded ? "expanded" : "closed"}
              variants={variants}
            />                
        </StyledHeader>
        <AnimatePresence initial={false}>
            {expanded && (
            <StyledSection
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 }
                }}
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
                <div>
                    {props.children}
                </div>
            </StyledSection>
            )}
        </AnimatePresence>
        </section>
    )
}
